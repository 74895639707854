import { FormData } from "./models/affidavitMxSlice.model";

const civilStateObjects = [
  {
    value: `Soltero`,
    label: "Soltero",
  },
  {
    value: `Casado por sociedad conyugal`,
    label: "Casado por sociedad conyugal",
  },
  {
    value: `Casado por bienes separados`,
    label: "Casado por bienes separados",
  },
  {
    value: `Divorciado`,
    label: "Divorciado",
  },
  {
    value: `Separación en proceso judicial`,
    label: "Separación en proceso judicial",
  },
  {
    value: `Viudo`,
    label: "Viudo",
  },
  {
    value: `Concubinato`,
    label: "Concubinato",
  },
]

const booleanOptions = [
  {
    value: `yes`,
    label: "Si",
  },
  {
    value: `no`,
    label: "No",
  },
];

const propertyOptions = [
  {
    value: `Escritura publica`,
    label: "Escritura publica",
  },
  {
    value: `Copia simple`,
    label: "Copia simple",
  },
  {
    value: `Ninguna`,
    label: "Ninguna",
  },
];

const helperProperty2 = {
  link: "",
  example:
    "https://d37d4134qoqd4p.cloudfront.net/EJEMPLOS+ESCITURAS.pdf",
  content: `<strong style="font-size: 17px; mix-blend-mode: difference;">Escritura pública:</strong> Es el documento que entrega el Notario ante quien se realizó la compraventa y que está inscrita ante las instituciones registrales que acreditan la titularidad del inmueble (Debe tener sellos y quinegramas impresos en los folios).
  <span style="height: 10px; display: flex;">\t</span>
  <strong style="font-size: 17px; mix-blend-mode: difference;">Copia simple:</strong> Es la reproducción del documento que el Notario realizó para formalizar la compraventa (Carece de sellos y quinegramas impresos en los folios que permitan asegurar la titularidad), generalmente se encuentra impreso en papel bond.`,
  label: "¿Qué es esto?",
  tooltip: true,
}

export const formState: FormData = {
  civil_state: {
    title: "Estado civil",
    subTitle: "",
    state: [
      {
        order: "h",
        type: "radio_btn",
        options: civilStateObjects,
        name: "civil_state",
        helper: {
          link: "",
          example:
            "https://d37d4134qoqd4p.cloudfront.net/estados_civiles.pdf",
          label: "¿Qué es esto?",
          tooltip: true,
          content: `<strong style="font-size: 17px; mix-blend-mode: difference;">Soltero:</strong> Que nunca ha contraído matrimonio.
          <span style="height: 10px; display: flex;">\t</span>
          <strong style="font-size: 17px; mix-blend-mode: difference;">Casado bajo el régimen de sociedad conyugal o Casado bajo el régimen de separación de bienes:</strong> En la imagen adjunta se especifica la parte del Acta de matrimonio en la que se puede consultar la información.`,
        },
        label: "¿Cuál es tu estado civil?",
        variant: "square",
      },
    ],
  },
  childs: {
    title: "Sobre RFC",
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: booleanOptions,
        name: "rfc",
        label:
          "¿Te encuentras inscrito y con estado activo en el Registro Federal de Contribuyentes (RFC)?",
        variant: "rounded",
        sizeComponent: "102px",
        helper: {
          link: "",
          label: "¿Qué es esto?",
          tooltip: true,
          example:
            "https://d37d4134qoqd4p.cloudfront.net/Registro+Federal+de+Contribuyentes.pdf",
          content:
            'Es la clave que otorga el Sistema de Administración Tributaria (SAT) a toda persona física y moral que realiza una actividad económica por la que está obligado a realizar el pago de impuestos, incluida la compra-venta de inmuebles, es importante que este en estado "Activo".',
        },
      },
    ],
  },
  contact_data: {
    title: "Datos de contacto",
    subTitle: "Por favor proporciónanos dos personas de contacto:",
    state: [
      {
        type: "input",
        placeholder: "Nombre",
        name: "contact_data_name_1",
        label: "Nombre 1",
        variant: "rounded",
      },
      {
        type: "input",
        placeholder: "Teléfono",
        name: "contact_data_phone_1",
        label: "Telefono 1",
        variant: "rounded",
        typeField: "number",
      },
      {
        type: "input",
        placeholder: "Nombre",
        name: "contact_data_name_2",
        label: "Nombre 2",
        variant: "rounded",
      },
      {
        type: "input",
        placeholder: "Teléfono",
        name: "contact_data_phone_2",
        label: "Telefono 2",
        variant: "rounded",
        typeField: "number",
      },
    ],
  },
  about_property: {
    title: "Sobre el inmueble",
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: booleanOptions,
        name: "about_property",
        label: "¿Tu inmueble esta rentado en este momento?",
        variant: "rounded",
        sizeComponent: "140px",
        componentsChilds: [
          {
            order: "v",
            type: "date_picker",
            value: new Date().toISOString(),
            options: booleanOptions,
            name: "about_property_date",
            label: "¿Hasta que fecha estará rentado?",
            subLabel:
              "Esta fecha es muy importante para el proceso, trata de ser lo mas preciso posible.",
            variant: "rounded",
            placeholder: "DD/MM/AAAA",
          },
        ],
      },
    ],
  },
  about_property_2: {
    title: " ",
    state: [
      {
        order: "h",
        type: "radio_btn",
        options: propertyOptions,
        name: "simple_document",
        helper: helperProperty2,
        label: "¿Cuentas con escrituras públicas y/o copia simple?",
        variant: "square",
      },
      {
        order: "v",
        type: "radio_btn",
        options: booleanOptions,
        name: "actual_process",
        label:
          "¿El inmueble está actualmente en un proceso de Adjudicación, Sucesión, Divorcio, Intestado u otro carácter judicial?",
        variant: "rounded",
        sizeComponent: "102px",
        helper: {
          link: "",
          label: "¿Qué es esto?",
          tooltip: true,
          content: `Cualquier proceso del que tiene conocimiento un Juez y que tiene que resolverse a través de una sentencia que determine quién es el titular del inmueble.`,
        },
      },
    ],
  },
};

export const formStateLegalizationMx: any = {
  civil_state_legalization: {
    title: "Estado civil ",
    subTitle: "",
    state: [
      {
        order: "h",
        type: "radio_btn",
        options: civilStateObjects,
        name: "owners_civil_states",
        helper: {
          link: "",
          example:
            "https://d37d4134qoqd4p.cloudfront.net/estados_civiles.pdf",
          label: "¿Qué es esto?",
          tooltip: true,
          content: `<strong style="font-size: 17px; mix-blend-mode: difference;">Soltero:</strong> Que nunca ha contraído matrimonio.
          <span style="height: 10px; display: flex;">\t</span>
          <strong style="font-size: 17px; mix-blend-mode: difference;">Casado bajo el régimen de sociedad conyugal o Casado bajo el régimen de separación de bienes:</strong> En la imagen adjunta se especifica la parte del Acta de matrimonio en la que se puede consultar la información.`,
        },
        label: "¿Cuál es tu estado civil?",
        variant: "square",
      },
    ],
  },
  childs_legalization: {
    title: "Sobre RFC ",
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: booleanOptions,
        name: "owners_tax_active",
        label:
          "¿Te encuentras inscrito y con estado activo en el Registro Federal de Contribuyentes (RFC)?",
        variant: "rounded",
        sizeComponent: "102px",
        helper: {
          link: "",
          label: "¿Qué es esto?",
          tooltip: true,
          example:
            "https://d37d4134qoqd4p.cloudfront.net/Registro+Federal+de+Contribuyentes.pdf",
          content:
            'Es la clave que otorga el Sistema de Administración Tributaria (SAT) a toda persona física y moral que realiza una actividad económica por la que está obligado a realizar el pago de impuestos, incluida la compra-venta de inmuebles, es importante que este en estado "Activo".',
        },
      },
    ],
  },
  about_property_legalization:{
    title: "Sobre el inmueble ",
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: booleanOptions,
        name: "rented",
        label: "¿Tu inmueble esta rentado en este momento?",
        variant: "rounded",
        sizeComponent: "140px",
        componentsChilds: [
          {
            order: "v",
            type: "date_picker",
            value: new Date().toISOString(),
            options: booleanOptions,
            name: "rented_date",
            label: "¿Hasta que fecha estará rentado?",
            subLabel:
              "Esta fecha es muy importante para el proceso, trata de ser lo mas preciso posible.",
            variant: "rounded",
            placeholder: "DD/MM/AAAA",
          },
        ],
      },
    ],
  },
  about_property_2_legalization: {
    title: "",
    state: [
      {
        order: "h",
        type: "radio_btn",
        options: propertyOptions,
        name: "deed_type",
        helper: helperProperty2,
        label: "¿Cuentas con escrituras públicas y/o copia simple.? ",
        variant: "square",
      },
      {
        order: "v",
        type: "radio_btn",
        options: booleanOptions,
        name: "is_in_legal_adjudication_process",
        label:
          "¿El inmueble está actualmente en un proceso de Adjudicación, Sucesión, Divorcio, Intestado u otro carácter judicial.? ",
        variant: "rounded",
        sizeComponent: "101px",
        helper: {
          link: "",
          label: "¿Qué es esto? ",
          tooltip: true,
          content: `Cualquier proceso del que tiene conocimiento un Juez y que tiene que resolverse a través de una sentencia que determine quién es el titular del inmueble. `,
        },
      },
    ],
  },
};
